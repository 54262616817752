import React from "react"
import { graphql, Link } from "gatsby"
import Layout from "../../components/layout"

export default function Blog({data}) {

	var isWIPMode = false;

	var blogs = [
		// { url: 'cs-refresher-p1', title: 'CS Refresher - Introduction to Bits', wip: true },
		// { url: 'introduction-to-hilbert-curve', title: 'Introduction to Hilbert Curve', wip: true },
		// { url: 'working-collaboratively-winning-largest-piece-of-the-pie', title: 'Working Collaboratively &amp; Winning the Largest Piece of the Pie', wip: true },

		{ url: 'cats-game', title: 'Cat Game', wip: false },
		{ url: 'tic-tac-toe', title: 'Tic Tac Toe Game', wip: false },
		{ url: 'geeks-unite-with-ensemble-programming', title: 'Geeks Unite with ensemble programming!', wip: false },
		{ url: 'introduction-to-alexa-skills', title: 'Introduction to Alexa Skills', wip: false },
		{ url: 'introduction-to-aws-kms', title: 'Introduction to AWS KMS', wip: false },
		{ url: 'git-scripting-on-windows', title: 'Scripting with Windows and Git', wip: false }
	];
	var wip = [];
	if (isWIPMode) {
		wip = blogs.filter(x=>x.wip).map(x=>{
			return (<li><Link to={'wip/'+x.url}>{x.title}</Link></li>);
		})
	}

	var published = blogs.filter(x => !x.wip).map(x => {
		return (<li><Link to={x.url}>{x.title}</Link></li>);
	})

	return (
		<Layout>
			<article>
				<h1>The random thoughts of a programmer....</h1>
				<ul>
				{wip}
				</ul>
				<ul>
				{published}
				</ul>
			</article>
		</Layout>
	)
}

export const query = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`
